nav {
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  width: 100%;
  background: black;
}
.link {
  text-decoration: none;
  display: flex;
  color: white;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}
.mobile-menu-icon {
  display: none;
}
.icons-navbar {
  display: flex;
  align-items: center;
}
.icon-tabler {
  margin-right: 5px;
}
.menu-items {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .menu-items,
  .signup-button,
  .github-corner {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
    color: white;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}
